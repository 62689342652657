angular.module('curalate').directive('experienceDetails', function() {
    return {
        scope: {},
        templateUrl: '/app/components/experiences/experience-details/experience-details.html',
        controller: ['$routeParams', '$scope', '$timeout', 'ExperiencesService', 'SessionVarsService', function($routeParams, $scope, $timeout, ExperiencesService, SessionVarsService) {
            $scope.ExperienceService = ExperiencesService;
            $scope.deployOutstanding = false;
            $scope.user = SessionVarsService.getCurrentUser();

            ExperiencesService.getExperience($routeParams.experienceName, $routeParams.siteName).then((experienceDetails) => {
                $scope.experience = experienceDetails.experience;
                $scope.versions = experienceDetails.versions;
                $scope.latestDeployment = experienceDetails.latestDeployment;

                _.each($scope.versions, (version) => {
                    version.options = version.optionsJson ? JSON.parse(version.optionsJson) : '';
                });
            });

            $scope.isDeployedDisabled = (versionId) => {
                return $scope.deployOutstanding && $scope.isVersionDeployed(versionId);
            };

            $scope.isVersionDeployed = (versionId) => {
                return $scope.latestDeployedVersion() && $scope.latestDeployedVersion().id === versionId;
            };

            $scope.promptDeployVersion = function(version) {
                $scope.versionToDeploy = version;

                angular.element('#experience-deploy-confirmation-modal').modal();

                ExperiencesService.getPendingMessage().then((message) => {
                    $scope.pendingMessage = message;
                });
            };

            $scope.deployVersion = (version) => {
                const pendingModal = angular.element('#experience-deploy-pending-modal').modal();

                $scope.deployOutstanding = true;
                ExperiencesService.deployExperienceVersion(version, $scope.user).then(function(deployment) {
                    $scope.latestDeployment = deployment;

                    $scope.deployResultMessage = 'Deployment Successful!';

                    $timeout(() => {
                        delete $scope.deployResultMessage;
                        pendingModal.modal('hide');
                    }, 3000);
                }).catch(function() {
                    $scope.deployResultMessage = 'Deployment Failed! Please try again.';

                    $timeout(() => {
                        delete $scope.deployResultMessage;
                        pendingModal.modal('hide');
                    }, 3000);
                }).finally(function() {
                    delete $scope.pendingMessage;
                    $scope.deployOutstanding = false;
                });
            };

            $scope.latestDeployedVersion = () => {
                return  _.find($scope.versions, (version) => {
                    return $scope.latestDeployment && version.id === $scope.latestDeployment.experienceVersionId;
                });
            };
        }]
    }
}); 
