angular.module('curalate').directive('experiencesList', function() {
    return {
        scope: {},
        templateUrl: '/app/components/experiences/experiences-list/experiences-list.html',
        controller: ['$scope', 'ExperiencesService', function($scope, ExperiencesService) {
            $scope.ExperienceService = ExperiencesService;
            $scope.searchQuery = '';

            $scope.onQueryChange = _.debounce((query) => {
                $scope.experiences = query ? _.filter($scope.allExperiences, (experience) => {
                    return JSON.stringify(experience).toLowerCase().indexOf(query.toLowerCase()) !== -1;
                }) : $scope.allExperiences;
            }, 100);

            ExperiencesService.getAllExperiences().then((experiences) => {
                $scope.allExperiences = _.clone(experiences);
                $scope.experiences = _.clone(experiences);
            });
        }]
    }
}); 
