angular.module('curalate').directive('siteDetails', function() {
    return {
        templateUrl: '/app/components/sites/site-details/site-details.html',
        controller: ['$routeParams', '$scope', '$timeout', 'ExperiencesService', 'SessionVarsService', function($routeParams, $scope, $timeout, ExperiencesService, SessionVarsService) {
            $scope.ExperienceService = ExperiencesService;
            $scope.deployOutstanding = false;
            $scope.user = SessionVarsService.getCurrentUser();

            ExperiencesService.getSite($routeParams.name).then((siteDetails) => {
                $scope.site = siteDetails.site;
                $scope.versions = siteDetails.versions;
                $scope.latestDeployment = siteDetails.latestDeployment;
                $scope.experiences = siteDetails.experiences;
            });

            $scope.isDeployDisabled = () => {
                return $scope.deployOutstanding;
            };

            $scope.isVersionDeployed = (versionId) => {
                return $scope.latestDeployedVersion() && $scope.latestDeployedVersion().id === versionId;
            };

            $scope.promptDeployVersion = function(version) {
                $scope.versionToDeploy = version;
                
                angular.element('#site-deploy-confirmation-modal').modal();

                ExperiencesService.getPendingMessage().then((message) => {
                    $scope.pendingMessage = message;
                });
            };

            $scope.deployVersion = function(version) {
                const pendingModal = angular.element('#site-deploy-pending-modal').modal();

                $scope.deployOutstanding = true;
                ExperiencesService.deploySiteVersion(version, $scope.user).then(function(deployment) {
                    $scope.latestDeployment = deployment;

                    $scope.deployResultMessage = 'Deployment Successful!';

                    $timeout(() => {
                        delete $scope.deployResultMessage;
                        pendingModal.modal('hide');
                    }, 3000);
                }).catch(function() {
                    $scope.deployResultMessage = 'Deployment Failed! Please try again.';

                    $timeout(() => {
                        delete $scope.deployResultMessage;
                        pendingModal.modal('hide');
                    }, 3000);
                }).finally(function() {
                    delete $scope.pendingMessage;
                    $scope.deployOutstanding = false;
                });
            };
            
            $scope.latestDeployedVersion = () => {
                return  _.find($scope.versions, (version) => {
                    return $scope.latestDeployment && version.id === $scope.latestDeployment.siteVersionId;
                });
            };

            $scope.previewContainer = function(containerName, siteVersion) {
                var previewUrl = ExperiencesService.getPreviewContainerUrl(containerName, $scope.site.name, siteVersion);
                window.open(previewUrl);
            };
        }]
    }
}); 
