angular.module('curalate').directive('sitesList', function() {
    return {
        scope: {},
        templateUrl: '/app/components/sites/site-list/site-list.html',
        controller: ['$scope', 'ExperiencesService', function($scope, ExperiencesService) {
            $scope.ExperienceService = ExperiencesService;
            $scope.searchQuery = '';

            $scope.onQueryChange = _.debounce((query) => {
                $scope.sites = query ? _.filter($scope.allSites, (site) => {
                    return JSON.stringify(site).toLowerCase().indexOf(query.toLowerCase()) !== -1;
                }) : $scope.allSites;
            }, 100);

            ExperiencesService.getAllSites().then((sites) => {
                $scope.allSites = _.clone(sites);
                $scope.sites = _.clone(sites);
            });
        }]
    }
}); 
