/* global angular:false */

((() => {
    const curalate = angular.module('curalate');

    curalate.directive('productsSummary', () => ({
        restrict: 'E',
        scope: {},
        templateUrl: '/app/components/products/summary/products-summary.html',

        controller: ['$scope', '$rootScope', 'ProductsService', '$location', '$timeout', 'UrlUtils',
            ($scope, $rootScope, ProductsService, $location, $timeout, UrlUtils) => {
                function resetNotification() {
                    $scope.style = '';
                    $scope.message = '';
                    $scope.showMessage = false;
                }

                $scope.$on('products:set-message', ($event, args) => {
                    $scope.message = args.message;
                    $scope.style = args.style;
                    $scope.showMessage = true;
                });

                $scope.processSearchByClientForm = () => {
                    const id = $scope.clientId;
                    $location.search('catalogId', null);
                    $location.search('clientId', id);
                    $timeout(() => {
                        resetNotification();
                        $rootScope.$broadcast('products:set-mode', { mode: 'display' });
                    }, 500);
                };

                $scope.processSearchByCatalogForm = () => {
                    const id = $scope.catalogId;
                    $location.search('clientId', null);
                    $location.search('catalogId', id);
                    $timeout(() => {
                        resetNotification();
                        $rootScope.$broadcast('products:set-mode', { mode: 'display' });
                    }, 500);
                }

                // if we come to the site via a link with the clientId set, we should load the catalog(s) right away.
                const clientIdParam = UrlUtils.getUrlParams(window.location.search).clientId;
                if (clientIdParam) {
                    $scope.clientId = parseInt(clientIdParam);
                    $scope.clientId = isNaN($scope.clientId) ? null : $scope.clientId;
                    $scope.processSearchByClientForm();
                }

                // if we come to the site via a link with the catalogId set, we should load the catalog right away.
                const catalogIdParam = UrlUtils.getUrlParams(window.location.search).catalogId;
                if (catalogIdParam) {
                    $scope.catalogId = parseInt(catalogIdParam);
                    $scope.catalogId = isNaN($scope.catalogId) ? null : $scope.catalogId;
                    $scope.processSearchByCatalogForm();
                }
            },
        ],
    }));
}))();
