/* global angular:false */

/* eslint no-underscore-dangle: ["error", { "allow": ["_passthroughFields"] }] */
((() => {
    const curalate = angular.module('curalate');
    const MOMENT_FORMAT = 'MMM-DD-YYYY, h:mm a Z';
    const toTaskLogUrl = taskId => (taskId !== undefined) ? `/v1/werk/tasks/${taskId}/log` : '';
    const toJouleUrl = (env, jouleId) => (jouleId !== undefined) ? `https://joule.us-east-1.${env}.curalate.com/instances/${jouleId}` : '';
    const toAcquisitionUrl = (s3Location) => `/api/products/acquisition/file/${encodeURIComponent(s3Location.bucket)}/${encodeURI(s3Location.key)}`;

    const parseCatalogData = function (data) {
        _.each(data, entry => {

            entry.catalog.created = moment(entry.catalog.created).format(MOMENT_FORMAT);
            entry.catalog.lastUpdated = moment(entry.catalog.lastUpdated).format(MOMENT_FORMAT);

            for (const fieldKey in entry.rawOpsFields) {
                try {
                    entry.rawOpsFields[fieldKey] = JSON.stringify(JSON.parse(entry.rawOpsFields[fieldKey]),null,2)
                } catch(e) {
                   // do nothing, the field will be printed raw since it's not valid JSON
                }
            }

            _.each(entry.acquisitions, acquisition => {
                acquisition.werkPath = toTaskLogUrl(acquisition.werkId);
                acquisition.joulePath = toJouleUrl(entry.env, acquisition.jouleId);
                acquisition.created = moment(acquisition.created).format(MOMENT_FORMAT);
                if (acquisition.s3FileLocation !== undefined) {
                    acquisition.downloadPath = toAcquisitionUrl(acquisition.s3FileLocation)
                }
            });
            _.each(entry.versions, versionEntry => {
                versionEntry.werkPath = toTaskLogUrl(versionEntry.werkId);
                versionEntry.joulePath = toJouleUrl(entry.env, versionEntry.jouleId);
                versionEntry.created = moment(versionEntry.created).format(MOMENT_FORMAT);
            });
            _.each(entry.changes, changeEntry => {
                changeEntry.created = moment(changeEntry.created).format(MOMENT_FORMAT);
            })
        });
    }

    curalate.directive('productsDisplayCatalogs', () => ({
        restrict: 'E',
        scope: {},
        templateUrl: '/app/components/products/display-catalogs/products-display-catalogs.html',

        controller: ['$scope', '$rootScope', 'ProductsService', 'UrlUtils', '$location', '$timeout', 'PRODUCTS_CONSTANTS',
            (
                $scope,
                $rootScope,
                ProductsService,
                UrlUtils,
                $location,
                $timeout,
                PRODUCTS_CONSTANTS
            ) => {
                // process the form
                $scope.clientId = UrlUtils.getUrlParams(window.location.search).clientId;
                $scope.catalogId = UrlUtils.getUrlParams(window.location.search).catalogId;
                $scope.catalogsData = [];
                $scope.displaySample = {};
                $scope.sample = {};
                $scope.header = PRODUCTS_CONSTANTS.SAMPLE_COLUMN_NAMES;

                if ($scope.clientId) {
                    ProductsService.getCatalogsByClientId($scope.clientId)
                        .then(result => {
                            const data = result.data;
                            parseCatalogData(data);
                            if (data.length > 0) {
                                $scope.catalogsData = data;
                            } else {
                                $rootScope.$broadcast('products:set-message', {
                                    message: `There are no catalogs for client: ${$scope.clientId}`,
                                    style: 'bg-danger' });
                            }
                        })
                        .catch(error => {
                            $rootScope.$broadcast('products:set-message', { message: error, style: 'bg-danger' });
                        });
                } else if ($scope.catalogId) {
                    ProductsService.getCatalogById($scope.catalogId)
                        .then(result => {
                            const data = result.data;
                            parseCatalogData(data);
                            if (data.length > 0) {
                                $scope.catalogsData = data;
                            } else {
                                $rootScope.$broadcast('products:set-message', {
                                    message: `There is no catalog: ${$scope.catalogId}`,
                                    style: 'bg-danger' });
                            }
                        })
                        .catch(error => {
                            $rootScope.$broadcast('products:set-message', { message: error, style: 'bg-danger' });
                        });
                }

                $scope.showSample = catalogId => {
                    if (!$scope.sample[catalogId]) {
                        ProductsService.getSample(catalogId)
                            .then(result => {
                                const data = result.data;
                                if (data.length > 0) {
                                    // set missing values to empty string
                                    for (const entry in data) {
                                        delete data[entry]._passthroughFields;
                                        const row = data[entry];
                                        for (const col in PRODUCTS_CONSTANTS.SAMPLE_COLUMN_NAMES) {
                                            const name = PRODUCTS_CONSTANTS.SAMPLE_COLUMN_NAMES[col];
                                            if (!name || !(name in row)) {
                                                row[name] = '--';
                                            }
                                        }
                                        // re-order columns to match the headers
                                        const fixedRow = {};
                                        for (const key in PRODUCTS_CONSTANTS.SAMPLE_COLUMN_NAMES) {
                                            const name = PRODUCTS_CONSTANTS.SAMPLE_COLUMN_NAMES[key];
                                            const value = row[name];
                                            fixedRow[name] = value;
                                        }

                                        data[entry] = fixedRow;
                                    }

                                    $scope.sample[catalogId] = data;
                                } else {
                                    $rootScope.$broadcast('products:set-message', {
                                        message: `There are no samples for catalog:${catalogId}`,
                                        style: 'bg-danger',
                                    });
                                }
                            })
                            .catch(error => {
                                $rootScope.$broadcast('products:set-message', { message: error, style: 'bg-danger' });
                            });
                    }
                    $scope.displaySample[catalogId] = !$scope.displaySample[catalogId];
                };
            },
        ],
    }));
}))();
